import React from "react"
import { AnimatePresence } from "framer-motion"
import { GatsbyBrowser } from "gatsby"

export const wrapPageElement: GatsbyBrowser["wrapPageElement"] = ({
	element,
}) => <AnimatePresence exitBeforeEnter>{element}</AnimatePresence>

// Scroll behavior
const transitionDelay = 1000

export const shouldUpdateScroll: GatsbyBrowser["shouldUpdateScroll"] = ({
	routerProps: { location },
	getSavedScrollPosition,
}) => {
	if (location.action === "PUSH") {
		window.setTimeout(() => window.scrollTo(0, 0), transitionDelay)
	} else {
		const savedPosition = getSavedScrollPosition(location)
		window.setTimeout(
			() => window.scrollTo(...(savedPosition || [0, 0])),
			transitionDelay
		)
	}
	return false
}
